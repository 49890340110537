import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Adjust the import path as necessary

const PrivateRoute = ({ element: Component }) => {
  const { user } = useAuth();

  return user ? <Component /> : <Navigate to="/" />;
};

export default PrivateRoute;
