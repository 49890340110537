import React, { useEffect, useState, useRef } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems, secondaryListItems } from './ListItems';
import { Button, TextField, ListItem, ListItemText, Checkbox } from '@mui/material';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage'; // Import Firebase storage module
import TodoList from './TodoList';
import 'firebase/compat/database';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';



function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="#">
        Studiyology
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function initializeFirebase() {
    // Initialize Firebase with your config
    const firebaseConfig = {
      apiKey: "AIzaSyD5kQtluauQ7YNtL824z3XeTI095VCAxUU",
      authDomain: "studiyologic.firebaseapp.com",
      databaseURL: "https://studiyologic-default-rtdb.asia-southeast1.firebasedatabase.app",
      projectId: "studiyologic",
      storageBucket: "studiyologic.appspot.com",
      messagingSenderId: "494288376509",
      appId: "1:494288376509:web:e7bfb94126bc6b00209dd4",
      measurementId: "G-EXP5TWSNRH"
    };

    // Check if Firebase is not already initialized
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }
  }

  function saveTaskToFirebase(task) {
    const tasksRef = firebase.database().ref('tasks');
    tasksRef.push(task);
  }

  function updateTaskInFirebase(taskId, updatedTask) {
    const taskRef = firebase.database().ref('tasks').child(taskId);
    taskRef.update(updatedTask);
  }

  function deleteTaskFromFirebase(taskId) {
    const taskRef = firebase.database().ref('tasks').child(taskId);
    taskRef.remove();
  }

  function fetchTasksFromFirebase(setTasks) {
    const tasksRef = firebase.database().ref('tasks');
    tasksRef.on('value', (snapshot) => {
      const tasks = snapshot.val();
      const tasksArray = tasks ? Object.keys(tasks).map(key => ({ id: key, ...tasks[key] })) : [];
      setTasks(tasksArray);
    });
  }


  function fetchVideoUrlsFromFirebaseStorage() {
    const storage = firebase.storage();
    const folderRef = storage.ref('Course'); // Adjust to your folder path in Firebase Storage

    return folderRef.listAll().then((res) => {
      const promises = res.items.map((itemRef) => itemRef.getDownloadURL());
      return Promise.all(promises);
    });
  }

const drawerWidth = 240;



const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Todolist() {
    const [open, setOpen] = React.useState(true);
    const [videoUrls, setVideoUrls] = useState([]);
    const [playStates, setPlayStates] = useState([]);
    const videoRefs = useRef([]);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    initializeFirebase();
    fetchTasksFromFirebase(setTasks); // Fetch tasks from Firebase on component mount
  }, []);

  const addTask = () => {
    if (newTask.trim()) {
      const task = { text: newTask, completed: false };
      saveTaskToFirebase(task); // Save new task to Firebase
      setNewTask('');
    }
  };

  const toggleTaskCompletion = (index) => {
    const task = tasks[index];
    const updatedTask = { ...task, completed: !task.completed };
    updateTaskInFirebase(task.id, updatedTask); // Update task in Firebase
  };

  const deleteTask = (index) => {
    const task = tasks[index];
    deleteTaskFromFirebase(task.id); // Delete task from Firebase
  };


  useEffect(() => {
    initializeFirebase(); // Initialize Firebase when component mounts
    fetchVideoUrlsFromFirebaseStorage().then(urls => {
      setVideoUrls(urls);
      setPlayStates(Array(urls.length).fill(false));
    }).catch(error => {
      console.error('Error fetching video URLs:', error);
    });
  }, []);

  useEffect(() => {
    // Disable right-click
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);


  const handlePlayPause = (index) => {
    const video = videoRefs.current[index];
    const newPlayStates = [...playStates];

    if (video.paused) {
      video.play();
      newPlayStates[index] = true;
    } else {
      video.pause();
      newPlayStates[index] = false;
    }

    setPlayStates(newPlayStates);
  };

  const getVideoName = (url) => {
    const parts = url.split('/');
    return decodeURIComponent(parts[parts.length - 1].split('?')[0]);
  };

  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState('');



  return (

    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Course Details
            </Typography>
            <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />

          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

          <Routes>
                <Route path="/" element={

            <Grid container spacing={3}>

              <Grid item xs={12} md={12} lg={12}>
              <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
            <Box sx={{ maxWidth: 600, margin: '0 auto', mt: 4 }}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >

                    <TextField
                      label="New Task"
                      value={newTask}
                      onChange={(e) => setNewTask(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          addTask();
                        }
                      }}
                    />
                    <Button onClick={addTask} variant="contained" color="primary" style={{margin:'5px'}}>
                      Add Task
                    </Button>

                  <List>
                    {tasks.map((task, index) => (
                      <ListItem key={index} dense>
                        <Checkbox
                          checked={task.completed}
                          onChange={() => toggleTaskCompletion(index)}
                        />
                        <ListItemText primary={task.text} />
                        <IconButton edge="end" onClick={() => deleteTask(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </ListItem>
                    ))}
                  </List>

                </Paper>
                </Box>
            </Grid>
          </Container>
              </Grid>
            </Grid>
            } />
            <Route path="todolist" element={<TodoList />} />
          </Routes>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}