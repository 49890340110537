// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Login from "./Component/Login";
import LandingPage from "./Component/Landingpage";
import Dashboard from './Component/Dashboard_copy';
import SignUp from './Component/Signup';
import TodoList from './Component/TodoList';
import Course from './Component/Course';
import AddCourse from './Component/AddCourse';
import LiveCodePage from './Component/LiveCodePage';
import Notes from './Component/Notes';
import ForgetPass from './Component/Forgetpassword';
import PrivateRoute from './Component/PrivateRoute'; // Import your PrivateRoute component
import Register from './Component/Register';
import { AuthProvider } from './Component/AuthContext';


function App() {

  return (
    <AuthProvider>
    <Router>
      <div className="App">

        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgetpass" element={<ForgetPass />} />

          {/* Protected routes using PrivateRoute */}
          <Route path="/dashboard" element={<PrivateRoute element={Dashboard} />} />
          <Route path="/dashboard/course" element={<PrivateRoute element={Course} />} />
          <Route path="/dashboard/todolist" element={<PrivateRoute element={TodoList} />} />
          <Route path="/dashboard/test" element={<PrivateRoute element={LiveCodePage} />} />
          <Route path="/dashboard/notes" element={<PrivateRoute element={Notes} />} />

          {/* Example of a public route */}
          <Route path="/addcourse" element={<AddCourse />} />

          <Route path="/register" element={<Register/>} />

        </Routes>

      </div>
    </Router>
    </AuthProvider>
  );
}

export default App;