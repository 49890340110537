import React, { useState } from 'react';
import { Grid, Link, TextField, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { auth, sendPasswordResetEmail } from '../firebase'; // Adjust the import path as per your Firebase setup

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}));

function ForgotPassword() {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [resetMessage, setResetMessage] = useState('');

  const handleResetPassword = async (event) => {
    event.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setResetMessage('Password reset email sent. Check your inbox.');
    } catch (error) {
      setResetMessage(`Error: ${error.message}`);
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <Grid container justify="center">
      <form className={classes.form} onSubmit={handleResetPassword}>
        <Grid item xs={12} align="center">
          <Typography variant="h6">Forgot Password?</Typography>
        </Grid>
        <Grid item xs={12} align="center">
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={handleEmailChange}
          />
        </Grid>
        <Grid item xs={12} align="center">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Reset Password
          </Button>
        </Grid>
        {resetMessage && (
          <Grid item xs={12} align="center">
            <Typography variant="body1">{resetMessage}</Typography>
          </Grid>
        )}
      </form>
    </Grid>
  );
}

export default ForgotPassword;
