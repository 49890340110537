// src/firebase.js
import firebase from 'firebase/compat/app';
import { initializeApp, getApps } from 'firebase/app';
import { getAuth,sendPasswordResetEmail } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';



const firebaseConfig = {
  apiKey: "AIzaSyD5kQtluauQ7YNtL824z3XeTI095VCAxUU",
  authDomain: "studiyologic.firebaseapp.com",
  databaseURL: "https://studiyologic-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "studiyologic",
  storageBucket: "studiyologic.appspot.com",
  messagingSenderId: "494288376509",
  appId: "1:494288376509:web:e7bfb94126bc6b00209dd4",
  measurementId: "G-EXP5TWSNRH"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.app(); // if already initialized, use that one
  }

let app;
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApps()[0];
}

// Export the initialized services
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export { sendPasswordResetEmail };
export default firebase;
