import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import { TextField, Button, Container, Typography, Box } from '@mui/material';

function initializeFirebase() {
  const firebaseConfig = {
    apiKey: "your-api-key",
    authDomain: "your-auth-domain",
    databaseURL: "your-database-url",
    projectId: "your-project-id",
    storageBucket: "your-storage-bucket",
    messagingSenderId: "your-messaging-sender-id",
    appId: "your-app-id",
    measurementId: "your-measurement-id"
  };

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }
}

const AddCourse = () => {
  const [courseName, setCourseName] = useState('');
  const [instructor, setInstructor] = useState('');
  const [duration, setDuration] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [password, setPassword] = useState('');
  const [authenticated, setAuthenticated] = useState(false);

  initializeFirebase();

  const handleSubmit = (e) => {
    e.preventDefault();
    const coursesRef = firebase.database().ref('courses');
    const course = {
      name: courseName,
      instructor: instructor,
      duration: duration,
      startDate: startDate,
      endDate: endDate,
    };
    coursesRef.push(course);
    setCourseName('');
    setInstructor('');
    setDuration('');
    setStartDate('');
    setEndDate('');
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    // Check if password matches
    if (password === '6457') {
      setAuthenticated(true);
    }
  };

  if (!authenticated) {
    return (
      <Container maxWidth="sm">
        <Box sx={{ mt: 4, mb: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Enter Pin to Access
          </Typography>
          <form onSubmit={handlePasswordSubmit}>
            <TextField
              label="Enter Pin"
              fullWidth
              margin="normal"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </form>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Add New Course
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Course Name"
            fullWidth
            margin="normal"
            value={courseName}
            onChange={(e) => setCourseName(e.target.value)}
          />
          <TextField
            label="Instructor"
            fullWidth
            margin="normal"
            value={instructor}
            onChange={(e) => setInstructor(e.target.value)}
          />
          <Button type="submit" variant="contained" color="primary">
            Add Course
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default AddCourse;
