// src/Dashboard.js
import React, { useEffect, useState, useRef } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems } from './ListItems';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import firebase from '../firebase'; // Import the Firebase config
import TodoList from './TodoList';
import { BrowserRouter as Router, Routes, Route,useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="#">
        Studiyology
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function fetchVideoUrlsFromFirebaseStorage() {
  const storage = firebase.storage();
  const folderRef = storage.ref('Course'); // Adjust to your folder path in Firebase Storage

  return folderRef.listAll().then((res) => {
    const promises = res.items.map((itemRef) => itemRef.getDownloadURL());
    return Promise.all(promises);
  });
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Dashboard() {
  const [open, setOpen] = useState(true);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [videoUrls, setVideoUrls] = useState([]);
  const [playStates, setPlayStates] = useState([]);
  const [progress, setProgress] = useState(0);
  const videoRefs = useRef([]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const videoRef = useRef(null);

  useEffect(() => {
    fetchVideoUrlsFromFirebaseStorage().then(urls => {
      setVideoUrls(urls);
      setPlayStates(Array(urls.length).fill(false));
    }).catch(error => {
      console.error('Error fetching video URLs:', error);
    });
  }, []);

  useEffect(() => {
    // Disable right-click
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);


  const getVideoName = (url) => {
    const parts = url.split('/');
    return decodeURIComponent(parts[parts.length - 1].split('?')[0]);
  };

  const handleLogout = () => {
    logout();
    navigate('/'); // Adjust the path as necessary
  };


  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Course Details
            </Typography>
            <IconButton color="inherit" onClick={handleLogout}>
              Logout
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Routes>
              <Route path="/" element={
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                      <Grid container spacing={3}>
                        {videoUrls.map((url, index) => (
                          <Grid key={index} item xs={12} md={12} lg={12}>
                            <Paper
                              sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: '80vh',
                                position: 'relative',
                              }}
                            >
                              {getVideoName(url)}
                            <video
        ref={videoRef}
        src={url}
        style={{ width: '100%', height: 'auto' }}

        controls
        controlsList="nodownload"
      />


<Typography
        variant="h6"
        sx={{ position: 'relative', top: '60px', left: '10px' }}
      >
        {getVideoName(url)}
      </Typography>
                            </Paper>

                          </Grid>
                        ))}
                      </Grid>
                    </Container>
                  </Grid>
                </Grid>
              } />
              <Route path="/notes" element={<TodoList />} />
            </Routes>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
