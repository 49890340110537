import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useAuth } from './AuthContext';

function Register() {
  const { userDetails } = useAuth();

  useEffect(() => {
    if (userDetails) {
      console.log('User registered:', userDetails);
    }
  }, [userDetails]);

  if (!userDetails) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 8 }}>
        <Typography variant="h5" gutterBottom>
          Loading...
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 8 }}>
      <Typography variant="h5" gutterBottom>
        Registration Successful
      </Typography>
      <Typography variant="body1" gutterBottom>
        Welcome, {userDetails.firstName}!
      </Typography>
      <Button variant="contained" color="primary" sx={{ mt: 2 }}>
        Register
      </Button>
    </Box>
  );
}

export default Register;
